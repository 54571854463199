$(document).ready(function() {
  let currentUrl = [
    location.protocol,
    "//",
    location.host,
    location.pathname
  ].join("");

  $(".calendar")
    .fullCalendar("destroy")
    .empty();
  setTimeout(function() {
    $(".calendar").fullCalendar({
      header: {
        left: "prev,next",
        center: "title",
        right: "today"
      },
      allDayDefault: true,
      startParam: "date_start",
      endParam: "date_end",
      events: {
        url: currentUrl + "json/" + location.search,
        success: function(response) {
          return response.events;
        }
      },
      loading: function(isLoading, view) {
        $(".events-cards")
          .html(`<div class="w-100 d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>`);
      },
      eventDataTransform: function(event) {
        return {
          title: event.title,
          start: event.date,
          url: event.url + "?date=" + event.date
        };
      },
      eventRender: function(event, element) {
        $(element).tooltip({
          title: event.title
        });
      },
      eventClick: function(calEvent) {
        window.location = calEvent.url;
      },
      viewRender: function(view, element) {
        let date_start = view.start.format("YYYY-MM-DD");
        let date_end = view.end.format("YYYY-MM-DD");
        let search = location.search;

        if (search) search = search + "&";
        else search = search + "?";

        search = search + "date_start=" + date_start + "&date_end=" + date_end;

        $.get(currentUrl + "partial/" + search, function(data) {
          $(".events-cards").html(data);
        });
      }
    });
  }, 100);
});
