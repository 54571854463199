$(document).ready(function() {
  let template = $('template#event-template')[0]
  if (!template) return
  fetch("https://imgoing-prod-api.xyz/api/v2.0/BurkeCoNC/events?limit=40&choice=true&key=tHHCyFgihl1qJuTRzeqAnTXoYlYRxnsmoNEoI45eiY")
  .then(res => res.json())
  .then(apiData => {
    let getDate = str => str && str.split('@')[0].trim()
    let getTime = str => str && str.split('@')[1].trim()
    let parent = $('div#eventsCarousel')
    apiData.events.forEach(event => {
      let el = template.content.cloneNode(true).children[0]
      $(el).find('a')[0].href = event.ImGoingLink
      $(el).find('img').attr({alt: event.name,
                              src: event.cover.source})
      $(el).find('.date').text(getDate(event.startTimeFormatted))
      $(el).find('.event-date').text(getDate(event.startTimeFormatted))
      let description = (event.description || '').replace('&amp;', '&').slice(0,250)
      $(el).find('.description').text(description)
      $(el).find('.location').text(event.address.address)
      $(el).find('.duration').text(`${getTime(event.eventTimes[0].startTimeFormatted)} - ${getTime(event.eventTimes[0].endTimeFormatted)}`)
      $(el).find('.event-title').text(event.title)
      parent.append(el)
    })

    $('#eventsCarousel').slick({
      dots: true,
      slidesToShow: apiData.events.length === 1 ? 1 : 2,
      slidesToScroll: apiData.events.length === 1 ? 1 : 2,
      arrows: true,
      prevArrow: $("#eventsPrev"),
      nextArrow: $("#eventsNext"),
      appendDots: $("#eventsDots"),
      responsive: [
        {
          breakpoint: 1199.98,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false
          }
        }
      ]
    })
  })
});
