$(document).ready(function() {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
        $(".navbar__ul-2").slideUp(350);
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
        $(".navbar__ul-2").slideDown(350);
      }
    }
  });

  // menu
  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    // $('#menu').slideToggle(350);
    $("#menu").animate({ width: "toggle" }, 350);
    if ($(this).hasClass("hamburger--clicked")) {
      $("body").css("overflow", "hidden");
      $(".navbar").addClass("navbar--opened");
      $(".navbar__ul-2").slideUp(350);
    } else {
      $("body").css("overflow", "auto");
      $(".navbar").removeClass("navbar--opened");
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar__ul-2").slideDown(350);
      }
    }
  });
});
