$(document).ready(function() {
  $(".accordion__btn").on("click", event => {
    $(event.currentTarget)
      .siblings(".collapse")
      .toggleClass("show");
    $(event.currentTarget)
      .children("h5")
      .children(".fi")
      .toggleClass("fi-f-vertical");
  });

  $(".block-table table").addClass("table table-light");
});
