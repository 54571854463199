// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faCalendarAlt,
  faClock,
  faCaretDown,
  faPaw,
  faArrowRight,
  faUniversalAccess
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faPinterestP,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(() => {
  library.add(
    faSearch,
    faTimes,
    faAngleRight,
    faArrowRight,
    faPhoneAlt,
    faAngleLeft,
    faMapMarkerAlt,
    faEnvelope,
    faFacebookF,
    faPinterestP,
    faInstagram,
    faYoutube,
    faUniversalAccess,
    faPrint,
    faPlus,
    faMinus,
    faAngleDown,
    faCalendarAlt,
    faClock,
    faCaretDown,
    faPaw
  );

  dom.watch();
});
