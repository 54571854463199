$(document).ready(function() {
  // show/hide filters on mobile
  $("#filtersBtn").on("click", function() {
    $("#show").toggle();
    $("#hide").toggle();
    $("#attractionFilters").slideToggle();
  });

  $("#attractionsCarousel").slick({
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: $("#attractionsPrev"),
    nextArrow: $("#attractionsNext"),
    appendDots: $("#attractionsDots"),
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: true
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
