$(document).ready(function() {
  $("form.news-filters")
    .find("input, select")
    .each(function() {
      $(this).change(function() {
        setTimeout(function() {
          const form = $("form.news-filters");
          window.location = "?" + form.serialize();
        }, 1000);
      });
    });
});
